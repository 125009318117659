import clsx from 'clsx';

import Slider from '#components/shared/ui/Slider';
import Content from '#components/shared/ui/Content';
import Typography from '#components/shared/ui/Typography';

import type { FC } from 'react';

import styles from './Testimonials.module.scss';

type Props = {
  title?: string;
  items: Array<{ name: string; text: string; feature?: string; img?: string }>;
  containerClasses?: string;
};

const Testimonials: FC<Props> = ({ title, items, containerClasses }) => {
  return (
    <Content classes={clsx(styles.content, containerClasses)}>
      {title && (
        <Typography component="h2" variant="h3" color="primary" className={styles.title}>
          {title}
        </Typography>
      )}
      <Slider
        prevSlideClass={styles.prevSlide}
        activeSlideClass={styles.activeSlide}
        nextSlideClass={styles.nextSlide}
        slides={items}
        renderSlide={(item, idx, { isActive }) => (
          <div className={`${styles.item} ${isActive ? styles.active : ''}`}>
            <div className={styles.head}>
              {item.img ? <img src={item.img} alt={item.name} width={42} height={42} /> : ''}
              <div>
                <Typography component="h3" variant="h4" color="branded">
                  {item.name}
                </Typography>
                {item?.feature ? (
                  <Typography component="span" variant="subtitle2" color="primary">
                    {item.feature}
                  </Typography>
                ) : null}
              </div>
            </div>
            <Typography component="p" variant="body2" color="secondary" className={styles.text}>
              {item.text}
            </Typography>
          </div>
        )}
      />
    </Content>
  );
};

export default Testimonials;
